/**
 * original founction library
 * [usage]
 * import url from '@/assets/lib/url'
 * mixins: [url]
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * 文字列に含まれるURL部分をリンクに変換する
     * @param {String} str 対象の文字列
     * @return {String} URL部分がリンクに変換された文字列
     */
    createAutolink (str) {
      const regexpUrl = /(((?:\w+:)?\/\/)?(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,})?)/g
      const regexpProtocol = /(https?:\/\/)/g
      // ユーザーの投稿した文字列をサニタイズ
      const sanitizedStr = this.$sanitize(str)

      // 文字列からURL該当箇所を検出してリンク作成
      return sanitizedStr.replace(regexpUrl, (...args) => {
        let href = ''
        if (args[0].match(regexpProtocol)) {
          // URLにプロトコル「http(s)://」がある場合
          // 例：https://google.com
          href = args[0]
        } else {
          if (args[0].match('//')) {
            // URLに不完全なプロトコルがある場合
            // 例：ttps://google.com
            const protocol = args[0].match('p://') ? 'http://' : 'https://'
            href = protocol + args[0].split('//')[1]
          } else {
            // URLにプロトコルがない場合
            // 例：google.com
            href = 'https://' + args[0]
          }
        }

        return '<a class="textlink" href="' + href + '" target="_blank" rel="noreferrer">' + args[0] + '</a>'
      })
    },
    /**
    * 正しいURLフォーマットか判定する
    * 具体的なパターンマッチの確認がしたい場合は下記サイトで入力
    * @see https://www.regextester.com/?fam=117238
    * @param {String} url 対象のURL
    * @return {Boolean} URLフォーマットが正しいかどうか
    */
    isValidURL (url) {
      const validUrlFormat = /((http|https):\/\/(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,}|\/)?)/g
      return url.match(validUrlFormat)
    }
  }
})
