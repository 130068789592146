<template>
  <div class="admin-delete" v-if="!isProcessing">
    <div class="admin-delete__user" v-for="(admin, uid) in admins" :key="uid">
      <user-detail class="admin-delete__user__detail" :user="admin" />
      <v-btn class="admin-delete__user__btn" @click="open(uid)" depressed>権限を削除する</v-btn>
    </div>
    <p v-if="Object.keys(admins).length === 0" class="admin-delete__warning">権限を削除できるユーザーがいません</p>
    <confirm-dialog ref="confirm" title="管理者権限の削除" msg="管理者の権限を削除すると管理者ページにアクセスできなくなります。"
                    @do-action="deleteAdmin()" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import UserDetail from '@/components/common/user/detail'
export default {
  name: 'admin_delete',
  components: { ConfirmDialog, UserDetail },
  data () {
    return {
      // 管理者一覧
      // { uid: {}, uid: {}, ... }
      admins: {},
      // 権限を削除するユーザーID
      uid: null
    }
  },
  async mounted () {
    // 管理者一覧の取得
    this.admins = await this.$store.dispatch('users/getAdmins')
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ダイアログを開いて、削除候補のユーザーIDを設定する
     * @param {String} uid ユーザーID
     */
    open (uid) {
      this.$refs.confirm.open()
      this.uid = uid
    },
    /**
     * 管理者権限の削除
     */
    async deleteAdmin () {
      this.$store.commit('setSubmitting', true)
      await this.$store.dispatch('users/updateUser', {
        uid: this.uid,
        params: {
          authority: 'member',
          updatedAt: new Date()
        }
      })
      // テロップを表示して移動
      this.$store.commit('setTelop', { show: true, msg: '管理者権限を削除しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.admin-delete {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__user {
    margin-top: 60px;
    &:first-child {
      margin-top: 0;
    }
    &__btn {
      display: block;
      margin: 20px auto 0 auto;
      font-size: 1.4rem;
      color: $white_color;
      border-radius: 8px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 8px 16px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
    }
  }
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
