<template>
  <div class="user-detail">
    <v-img class="user-detail__icon" :src="user.icon" />
    <p class="user-detail__name">{{ user.name }}</p>
    <h2 class="user-detail__title">プロフィール</h2>
    <p class="user-detail__profile" v-html="createAutolink(user.profile)" />
  </div>
</template>

<script>
import url from '@/assets/lib/url'
export default {
  mixins: [url],
  props: {
    // ユーザー情報
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.user-detail {
  &__icon {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: $gray_lighten_color;
    border-radius: 50%;
  }
  &__name {
    margin: 0;
    margin-top: 10px;
    font-size: 1.4rem;
    text-align: center;
  }
  &__title {
    margin-top: 20px;
    font-size: 1.2rem;
  }
  &__profile {
    margin: 0;
    margin-top: 10px;
    font-size: 1.2rem;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
</style>
